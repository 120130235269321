:root {
  --gap: 2em;
  --primary-color: #081e30;
  --transitionLength: 0.5s;
  --transitionTiming: ease;
}

.txt-jf {
  text-align: justify;
}

.row {
  display: flex;
  flex-wrap: wrap;
  /* margin: 1rem 0; */
  justify-content: center;
  box-sizing: border-box;
  gap: var(--gap);
  /* align-items: center; */
  /* row-gap: 2px */
}

.row-no-wrap {
  display: flex;
  margin: 0;
  justify-content: space-between;
  box-sizing: border-box;
  gap: var(--gap);
  align-items: center;
  row-gap: 2px
}

.col-1 {
  width: 100%;
}

.col-30 {
  width: calc(30% - var(--gap));
  box-sizing: border-box;
}

.col-40 {
  width: calc(40% - var(--gap));
  box-sizing: border-box;
}

.col-50 {
  width: 50%;
}

.col-60 {
  width: calc(60% - var(--gap));
  box-sizing: border-box;
}

.col-70 {
  width: calc(70% - var(--gap));
  box-sizing: border-box;
}

.col-80 {
  width: 80%;
  box-sizing: border-box;
}

.col-2 {
  width: calc((100%/2) - var(--gap));
  box-sizing: border-box;
  /* width: 50%; */
}

.col-3 {
  width: calc((100%/3) - var(--gap));
  box-sizing: border-box;
}

.col-4 {
  width: calc((100%/4) - var(--gap));
  box-sizing: border-box;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: black;
  padding: 15px 0;
  width: 100%;
}

.flx {
  display: flex;
}

.flx-dr-cl {
  flex-direction: column;
}

.flx-v-c {
  align-items: center;
}

.flx-h-c {
  justify-items: center;
  justify-content: center;
}

.section-bg {
  background-color: #f3f5fa;
  padding: 12px 0;
  text-align: center;
}

.client-img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
  filter: grayscale(200);
}

.client-img:hover {
  scale: 1.2;
  filter: none;
}

.client-icon {
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  width: 150px;
}


.animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

.section-title {
  text-align: center;
  padding: 50px 0;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #E30C0F;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #E30C0F;
  bottom: 0;
  left: calc(50% - 20px);
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.fade-in{
  animation: fadein 3s;
}

.img-in {
  animation: fadein 3s, up-down 2s ease-in-out infinite alternate-reverse both;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInUp {
  animation: 1.5s fadeInUp;
}

.about {
  padding: 0 30px;
}

.svc-box {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  transition: all ease-in-out 0.5s;
  background: #fff;
  text-align: center;
  cursor: pointer;
}

.svc-box:hover {
  transform: translate(0, -15px);
}

.svc-box h4 {
  color: #670103;
  transition: ease-in-out 0.3s;
  font-weight: 600;
  font-size: 20px;
}

.svc-box h4:hover {
  color: #be0003;
}

.sec-faq {
  padding-bottom: 6em;
}

.faq-list {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.getstarted,
.getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #E30C0F;
  font-weight: 600;
  cursor: pointer;
}

.getstarted:hover,
.getstarted:focus:hover {
  color: white !important;
  background: #E30C0F;
}

.logo {
  font-size: 30px;
  width: 30%;
  height: 30%;
  margin: 0;
  margin-right: 0px;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.navbar {
  padding: 0;
  display: flex;
  align-items: center;
}

.navbar ul,
.nav-sidebar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  height: 100%;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
  text-decoration: none;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #E30C0F;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #E30C0F;
  font-weight: 600;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #E30C0F;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: #0c3c53;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #47b2e4;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}



.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
  width: 30px;
  height: 30px;
  /* background-color: #cecece; */
  margin: 5px;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #530405;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #E30C0F;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
  color: #530405;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #47b2e4;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}



.services {
  padding-top: 50px;
  padding-bottom: 50px;
}

.nav-top-menu {
  color: white;
}

.nav-top-menu:hover {
  cursor: pointer;
}

.nav-top-menu:focus {
  color: #E30C0F;
}

.menu-active {
  color: #E30C0F !important;
}

.sec-home {
  background-color: black;
}

.sec-home h1 {
  margin: 0 0 10px 0;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
}

.sec-home h5 {
  color: rgba(255, 255, 255, 0.911);
  margin: 5px 0;
}

.sec-home h2 {
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  /* margin-bottom: 50px; */
  font-size: 20px;
}

.btn-get-started {
  font-family: "Jost", sans-serif;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: #fff;
  background: #E30C0F;
  cursor: pointer;
}

.btn-get-started:hover {
  color: white;
}

.sec-about {
  padding: 30px 0;
}


.about h3 {
  font-size: 1.75rem;
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 600;
  line-height: 1.2;
}



/* accordion */
.acc-container {
  max-width: 800px;
  width: 100%;
}

.acc-wrapper {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
  margin-bottom: 20px;
}

.question-container:hover {
  color: #d60000;
}

.acc-wrapper .question-container {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.1rem;
  color: #464646;
  background: transparent;
  border: none;
  cursor: pointer;
}

.question-container p {
  margin: 0;
}

.question-container.active {
  color: #d60000;
  font-weight: 600;
}

.acc-wrapper .arrow {
  font-size: 2rem;
  transition: .5s ease-in-out;
}

.arrow.active {
  rotate: 180deg;
  color: #d60000;
}

.acc-wrapper .answer-container {
  padding: 0 0.3rem;
  transition: height .7s ease-in-out;
}

.acc-wrapper .answer-content {
  padding: 0.5rem 0;
  font-size: 1rem;
  text-align: left;
}

.sec-team {
  padding-bottom: 5em;
}

.sec-contact {
  padding-bottom: 40px;
}

.icon-contact {
  font-size: 20px;
  color: #ed3c0d;
  float: left;
  min-width: 44px;
  height: 44px;
  background: #e7f5fb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.icon-contact:hover {
  background: #ed3c0d;
  color: #ffffff;
}

.contact-info {
  border-top: 3px solid #de393b;
  border-bottom: 3px solid #de393b;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

.contact-info iframe {
  border: none;
}

.contact-detail {
  align-items: flex-start;
  justify-content: flex-start;
}

.contact-sub-detail h4 {
  margin: 0;
  font-weight: 600;
  /* padding: 0 0 0 60px; */
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #464646;
}

.contact-sub-detail h6 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 2px;
  color: #5a5a5a;
}

.contact-sub-detail {
  display: flex;
  flex-direction: column;
  justify-content: left;
  justify-items: left;
}

.contact-sub-detail p {
  margin: 0;
  margin-bottom: 20px;
  font-size: 14px;
  color: #7a7a7a;
}

.contact-map {
  height: 100%;
  width: 100%;
}

.footer-copyright {
  font-size: 0.8rem;
  color: white;
  background-color: #af0003;
  padding: 8px 0;
}

.sec-footer {
  padding: 60px 0 0 0;
  background-color: black;
}

.footer-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  color: #cecece;
  padding: 0 1em;
}

.footer-info strong,
.footer-info h4 {
  margin: 10px 0 0 0;
  color: #E30C0F;
}

.footer-info p {
  margin: 0;
}

.footer-link-opt {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: flex-start;
  text-align: left;
}

.link-opt {
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.team-desc {
  text-align: center;
  padding: 0 3rem;
}

.link-opt a {
  text-decoration: none;
  color: #cecece;
}

.link-opt:hover,
.link-opt a:hover {
  color: #E30C0F;
}

.icon-opt {
  font-size: 2rem;
}

.opt-active {
  color: #E30C0F;
}

.back-top {
  display: flex;
  background-color: #bd0000;
  width: 30px;
  height: 30px;
  padding: 8px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.logo-footer {
  width: 70%;
  height: 70%;
  margin-bottom: 20px;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}


.answer-container hr {
  color: #f5f5f5 !important;
}

.sidebar {
  background-color: rgb(39, 39, 39);
  /* width: 90%;
  height: 100%; */
  /* flex-wrap: nowrap; */
  width: 90vw;
  /* width: 400px; */
  height: 100vh;
  position: fixed;
  top: 0px;
  padding: 0px 30px;
  right: 0px;
  z-index: 9999;
  transition: .3s ease;
}

.logo-sidebar {
  /* height: fit-content;
  width: fit-content; */
  /* width: 90px;
  height: 70px; */
  transform: scale(0.8);
}

.sidebar-top {
  margin-top: 20px;
  justify-content: space-between;
}

.nav-sidebar {
  color: #cecece;
  margin: 40px 0;
  font-weight: 600;

}

.nav-sidebar ul {
  display: flex;
  flex-direction: column !important;
}

.nav-sidebar ul li {
  margin: 10px;
}

.nav-sidebar-menu {
  color: inherit;
  cursor: pointer;
}

.nav-sidebar-menu:hover {
  color: #af0003 !important;
}

.close-icon-sidebar {
  cursor: pointer;
}

.portofolio-gallery {
  padding: 20px;
}

.social-icon {
  /* font-size: 18px; */
  display: inline-block;
  background: #E30C0F;
  color: #fff;
  /* line-height: 1; */
  padding: 5px;
  margin-top: 5px;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 19px;
  height: 19px;
  transition: 0.3s;
  cursor: pointer;
}

.social-icon:hover {
  background-color: #ffffff;
  color: #E30C0F;
}

.loading-page,
.err-401 {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}


@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}


@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}


@media screen and (max-width: 720px) {
  [class*="col-"] {
    width: 100%;
    /* padding: 1em; */
    /* padding: 20px; */
  }

  .svc-box {
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0 40px;
  }

  .about h3 {
    text-align: center;
  }

  .hero {
    flex-direction: column-reverse;
    text-align: center;
  }

  .faq-list,
  .contact-info {
    margin: 0 40px;
  }

  .footer-info {
    padding: 0 40px;
  }

  .sec-home {
    padding: 20px 40px;
  }

  .logo {
    margin-left: 10px;
  }

  .team-desc {
    text-align: justify;
  }
}